import React, { useEffect, useState } from "react";
import loadable from "@loadable/component";
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import Seo from "../components/seo"

import StickyButtons from "../components/StickyButtons/StickyButtons";

const Banner = loadable(() => import("../components/Banner/Banner"));
const StatsModule = loadable(() => import("../components/StatsModule/StatsModule"));
const TileBlock = loadable(() => import("../components/TileBlock/TileBlock"));
const OurServicesBlock = loadable(() => import("../components/OurServicesBlock/OurServicesBlock"));
const FeaturedProperties = loadable(() => import("../components/FeaturedProperties/FeaturedProperties"));
const ReviewsModule = loadable(() => import("../components/ReviewsModule/ReviewsModule"));
const CtaModule = loadable(() => import("../components/CtaModule/CtaModule"));
const NewsEvents = loadable(() => import("../components/NewsEvents/NewsEvents"));
const NewsLetterModule = loadable(() => import("../components/NewsLetterModule/NewsLetterModule"));

const IndexPage = ({ data }) => {
    const PageData = data?.strapiPage

    const [renderComponent, setRenderComponent] = useState(false);
    useEffect(() => {
        window.addEventListener("mousemove", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        })
        window.addEventListener("touchmove", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        })
        window.addEventListener("keypress", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        })

    }, [])

    return (
        <Layout footerCta="footercta" popularSearch={PageData?.select_popular_search?.title}>
            <div className="layout-padding-top"></div>

            {PageData.banner &&
                <Banner
                    {...PageData.banner}
                    title={PageData.banner?.banner_title}
                    id={PageData.strapi_id}
                    imagetransforms={PageData.imagetransforms}
                    tag="home"
                />
            }

            {PageData?.Add_Page_Modules?.length > 0 && PageData.Add_Page_Modules?.map((module, index) => {
                return (
                    <>
                        {module.strapi_component === "page-modules.global-module" && module.select_module === "latest_news_slider" && <NewsEvents {...module} />}
                        {module.strapi_component === "page-modules.stats-module" && <StatsModule tag="pb-0" {...module} />}

                        {(module.strapi_component === "page-modules.image-and-content" && module.layout === "with_accordion") &&
                            <OurServicesBlock {...module} imagetransforms={PageData.imagetransforms} id={PageData.strapi_id}/>
                        }
                        {(module.strapi_component === "page-modules.image-and-content" && module.layout !== "with_accordion") &&
                            <TileBlock {...module} imagetransforms={PageData.imagetransforms} id={PageData.strapi_id}/>
                        }
                        {module.strapi_component === "page-modules.global-module" && module.select_module === "cta_module" &&
                            <CtaModule />
                        }
                        {module.strapi_component === "page-modules.global-module" && module.select_module === "google_reviews_slider" && renderComponent &&
                            <ReviewsModule {...module} />
                        }
                        {module.strapi_component === "page-modules.global-module" && module.select_module === "featured_properties_sales" && renderComponent &&
                            <FeaturedProperties {...module} />
                        }
                        {module.strapi_component === "page-modules.global-module" && module.select_module === "newsletter_module" &&
                            <NewsLetterModule {...module} />
                        }
                    </>
                )
            })}

            <StickyButtons tag="default" />

        </Layout>
    )
}



export const Head = ({ data }, props) => {
    const PageData = data?.strapiPage
    const siteData = data?.strapiSiteConfig

    var schemaSameAs = ''
    if (siteData?.facebook_link.length > 1) {
        schemaSameAs = siteData?.facebook_link + ','
    }
    if (siteData?.twitter_link.length > 1) {
        schemaSameAs += siteData?.twitter_link + ','
    }
    if (siteData?.instagram_link.length > 1) {
        schemaSameAs += siteData?.instagram_link + ','
    }
    if (siteData?.linkedin_link.length > 1) {
        schemaSameAs += siteData?.linkedin_link + ','
    }

    var ldJson = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "url": process.env.GATSBY_SITE_URL,
        "name": process.env.GATSBY_SITE_NAME,
        "alternateName": process.env.GATSBY_SITE_NAME,
        "logo": process.env.GATSBY_SITE_URL + `/images/logo.png`,
        "contactPoint": {
            "@type": "ContactPoint",
            "telephone": siteData?.mobile_device_phone,
            "areaServed": process.env.GATSBY_DEFAULT_AREA,
            "contactType": "Sales"
        },
        "sameAs": schemaSameAs.replace(/,\s*$/, "").split(',')
    };

    return (
        <Seo title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title}>
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJson) }}
            />
        </Seo>
    )
}


export default IndexPage



export const query = graphql`
    query ($page_id: String) {
      strapiPage(id: {eq: $page_id}) {
          ...PageFragment
          Add_Page_Modules {
                ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
                    ...GlobalModuleFragment
                }
                ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE_AND_CONTENT {
                    ...ImageAndContentFragment
                }
                ... on STRAPI__COMPONENT_PAGE_MODULES_TEXT_MODULE {
                    ...TextModuleFragment
                }
                ... on STRAPI__COMPONENT_PAGE_MODULES_STATS_MODULE {
                    ...StatsModuleFragment
                }
                ... on STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT {
                    ...PlainContentFragment
                }
          }
      }
  
      
      strapiSiteConfig {
          twitter_link
          instagram_link
          linkedin_link
          facebook_link
          add_contact_details {
            phone
          }
        }
    }
  `